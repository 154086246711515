
import './App.css';
import Home from './home';
import React from "react";
import './index.css';

const App = () => {
  return (
    <div className="bg">

<Home></Home>
    </div>
  );
}

export default App;


