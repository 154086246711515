import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaGooglePlay } from "react-icons/fa";
import {

  AiFillLinkedin,
 
} from "react-icons/ai";

export default function Home() {
  return (
    <Container>
      <Row>
        <Col xs={7}></Col>
        <Col className="home">
          <Row>
            <h1 class="font-weight-bold' font, fs-1">
              Owls Apps
            </h1>
          </Row>
          <Row>
            <h6>Makes your life easier</h6>
          </Row>
          <Row>
            <Container class="social">
            
              <Row>
                <Col>
                  <div>
                    <a href="https://play.google.com/store/apps/dev?id=5763751992774977681&hl=tr&gl=TR">
                      <FaGooglePlay size={50} color="black" />
                    </a>
                  </div>
                </Col>
                <Col>
                <a href="https://www.linkedin.com/company/owls-apps">
                  <AiFillLinkedin size={55} color="black" />
                </a>
              </Col>
              <Col xs={8}></Col>
              </Row>
            </Container>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
